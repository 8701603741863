import { useState, useEffect } from 'react'
import { ethers } from "ethers"
import { Row, Col, Card, Button } from 'react-bootstrap'

export default function MyNFT({ marketplace, nft, account }) {
  const [loading, setLoading] = useState(true)
  const [purchases, setPurchases] = useState([])

  const loadPurchasedItems = async () => {
    
    const sentLogs = await nft.queryFilter(
      nft.filters.Transfer(account, null),
    );
    const receivedLogs = await nft.queryFilter(
      nft.filters.Transfer(null, account),
    );
  
    const logs = sentLogs.concat(receivedLogs)
      .sort(
        (a, b) =>
          a.blockNumber - b.blockNumber ||
          a.transactionIndex - b.TransactionIndex,
      );
        
      console.log(account)
    const owned = new Set();
    
    let nfts = [];

    for (const log of logs) {
      const { from, to, tokenId } = log.args;
      
      if (to.toLowerCase() == account.toLowerCase()) {
        owned.add(tokenId.toString());
      } else if (from.toLowerCase() == account.toLowerCase()) {
        owned.delete(tokenId.toString());
      }
    }
  
    for (const tokenId of owned) {
      const uri = await nft.tokenURI(tokenId);
      const response = await fetch(uri);
      const metadata = await response.json();
      let purchasedItem = {
        name: metadata.name,
        description: metadata.description,
        image: metadata.image
      }
      nfts.push(purchasedItem);
    }


    setLoading(false)
    setPurchases(nfts)
  }

  const sellPurchasedNFT = async (item) => {
    const listingPrice = ethers.utils.parseEther("0.05")
    await (
      await marketplace.sellPurchasedNFT(item.itemId, listingPrice)
    ).wait();
    loadPurchasedItems();
  }

  useEffect(() => {
    loadPurchasedItems()
  }, [])

  if (loading) return (
    <main style={{ padding: "1rem 0" }}>
      <h2>Loading...</h2>
    </main>
  )
  return (
    <div className="flex justify-center">
      {purchases.length > 0 ?
        <div className="px-5 container">
          <Row xs={1} md={2} lg={4} className="g-4 py-5">
            {purchases.map((item, idx) => (
              <Col key={idx} className="overflow-hidden">
                <Card>
                  <Card.Img variant="top" src={item.image} />
                  <Button onClick={() => sellPurchasedNFT(item)}>Sell</Button>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        : (
          <main style={{ padding: "1rem 0" }}>
            <h2>No purchases</h2>
          </main>
        )}
    </div>
  );
}